* {
  font-family: "Inter", sans-serif !important;
  -webkit-tap-highlight-color: transparent;
  /* -webkit-user-select: none !important;  */
  /* -moz-user-select: none !important;  */
  /* -ms-user-select: none !important;  */
  /* user-select: none !important; */
}

.mapboxgl-ctrl,
.mapboxgl-ctrl-bottom-right {
  opacity: 0;
}

html {
  overflow: clip;
}

td {
  font-weight: 500 !important;
}

/* COLORS */
:root {
  --p50: #d7f3ff;
  --p100: #b9ecff;
  --p200: #88e2ff;
  --p300: #50cfff;
  --p400: #28b3ff;
  --p500: #0693ff;
  --p600: #0a7eeb;
  --p700: #0f64be;
  --p800: #135695;
  --p900: #11345a;
  --p1000: #061d36;
  --p1100: #02101d;

  --p500a1: rgba(6, 147, 255, 0.8);
  --p500a2: rgba(6, 147, 255, 0.6);
  --p500a3: rgba(6, 147, 255, 0.4);
  --p500a4: rgba(6, 147, 255, 0.2);
  --p500a5: rgba(6, 147, 255, 0.1);
  --p600a: #2ea4ff94;
  --p900a: #016cd7d0;

  --divider: #7e7e7e15;
  --divider2: #7e7e7e22;
  --divider3: #7e7e7e40;
  --divider-text: #858585c1;

  --dark: #181818;

  --blue: #1672fc;
  --bluea: #1672fc40;
  --greena: #16fc6a3e;
  --yellowa: #fcf81641;
  --orange: #ff8c00;
  --orangea: #ffb45953;
  --red: #e53e3e;
  /* --reda: #ff5d5d2d; */
  --reda: rgba(255, 109, 109, 0.12);
  --reda2: rgba(255, 109, 109, 0.2);
  --reda3: rgba(255, 109, 109, 0.2);
}

*:focus-visible {
  transition: 200ms !important;
  box-shadow: none !important;
  /* border: 1px solid var(--p500); */
  /* box-shadow: 0 0 0 2px var(--p200) !important; */
}

/* .num {
  font-family: "Roboto Mono", monospace !important;
} */

::selection {
  background: var(--p500);
  color: white;
}

th {
  color: var(--divider-text) !important;
}

a {
  font-size: 14px !important;
  text-decoration: none !important;
}

p {
  font-size: 14px;
}
label {
  font-size: 14px !important;
}
td {
  font-size: 14px;
  border-bottom: none !important;
}
th {
  font-size: 12px !important;
  text-transform: none !important;
  border-bottom: 1px solid var(--divider3) !important;
}

button {
  font-size: 14px !important;
}
button:focus-visible {
  border: 1px solid var(--p500) !important;
  box-shadow: none !important;
}

.btn {
  background: transparent !important;
}
.btn-outline {
  background-color: transparent !important;
  border: 1px solid var(--divider3);
}
.btn-solid {
  background: var(--divider) !important;
}

.btn:hover:not(:disabled),
.btn-outline:hover:not(:disabled) {
  background: var(--divider) !important;
}
.btn-solid:hover:not(:disabled) {
  background: var(--divider2) !important;
}

.btn:active:not(:disabled),
.btn-outline:active:not(:disabled) {
  background: var(--divider2) !important;
}
.btn-solid:active:not(:disabled) {
  background: var(--divider3) !important;
}

.btn-ap:not(:disabled) {
  background: var(--p500) !important;
  /* color: white !important; */
}
.btn-ap:hover:not(:disabled) {
  background: var(--p600) !important;
}
.btn-ap:active:not(:disabled) {
  background: var(--p700) !important;
}

.btn-apa:not(:disabled) {
  background: var(--p500a5) !important;
  color: var(--p500) !important;
}
.btn-apa:hover:not(:disabled) {
  background: var(--p500a4) !important;
}
.btn-apa:active:not(:disabled) {
  background: var(--p500a3) !important;
}

.btn-clear {
  background: none !important;
  padding: 0 !important;
}
.btn-clear:hover {
  background: none !important;
}
.btn-clear:active {
  background: none !important;
}

.clicky {
  transition: 200ms;
}
.clicky:active:not(:disabled) {
  transform: scale(0.95);
}
.sm-clicky:active:not(:disabled) {
  transform: scale(0.9);
}

.lg-clicky:active:not(:disabled) {
  transform: scale(0.97);
}

input,
textarea {
  border-radius: 8px !important;
  outline: none !important;
  border: 1px solid var(--divider3) !important;
  font-size: 14px !important;
  /* backdrop-filter: blur(10px); */
}
input::placeholder,
textarea::placeholder {
  /* opacity: 0.5; */
  color: #96969691;
}
input:focus,
input:focus-visible,
input.filled:focus,
input.filled:focus-visible,
select:focus,
textarea:focus,
textarea:focus-visible,
textarea.filled:focus,
textarea.filled:focus-visible {
  transition: 200ms !important;
  /* box-shadow: 0 0 0 2px var(--p500) !important; */
  box-shadow: none !important;
  border: 1px solid var(--p500) !important;
}
input.filled {
  border: 1px solid var(--divider3) !important;
  background: var(--divider) !important;
}
input.filled:focus,
input.filled:focus-visible {
  background: transparent !important;
}

select {
  border: 1px solid var(--divider3) !important;
  font-size: 14px !important;
}

button[aria-label="Close"] {
  background: transparent !important;
  top: 2px !important;
  right: 2px !important;
}
button[aria-label="Close"] > svg {
  width: 12px !important;
}

.chakra-alert {
  border-radius: 12px !important;
  padding: 16px !important;
  /* min-width: calc(100vw - 32px) !important; */
  /* max-width: 700px !important; */
}
.chakra-alert__title {
  font-size: 14px;
  /* line-height: normal !important; */
  margin-right: 24px !important;
}
.chakra-alert__desc {
  font-size: 14px;
  line-height: normal !important;
  margin-top: 4px;
  max-width: 450px !important;
}
.alert {
  min-width: 100% !important;
  border-radius: 8px;
}
.css-o807cm[data-active],
.css-1veby0y[data-active],
.css-1nz3jkt[data-active] {
  background: var(--divider3) !important;
}

.chakra-form__error-message {
  font-size: 12px !important;
  font-weight: 500;
}
.chakra-form__helper-text {
  font-size: 12px !important;
}

.noScroll {
  -ms-overflow-style: none;
  scrollbar-width: none;
}
.noScroll::-webkit-scrollbar {
  display: none;
}

.scrollX::-webkit-scrollbar {
  height: 4px;
}
.scrollX::-webkit-scrollbar-corner,
.scrollY::-webkit-scrollbar-corner {
  background: transparent !important;
}

.scrollY::-webkit-scrollbar {
  width: 4px;
}

.scrollX::-webkit-scrollbar-thumb,
.scrollY::-webkit-scrollbar-thumb {
  background-color: var(--divider2);
  border-radius: 100px;
}

.scrollXkotak::-webkit-scrollbar-thumb,
.scrollYkotak::-webkit-scrollbar-thumb {
  background-color: var(--divider2);
  border-radius: 0px !important;
}

.scrollX::-webkit-scrollbar-thumb:hover,
.scrollY::-webkit-scrollbar-thumb:hover {
  background-color: var(--divider3);
}

.scrollX::-webkit-scrollbar-track,
.scrollY::-webkit-scrollbar-track {
  background-color: transparent;
  border-radius: 0 0 8px 8px;
}

/* big scroll bar */
.scrollXbig::-webkit-scrollbar {
  height: 12px !important;
}
.scrollXbig::-webkit-scrollbar-corner,
.scrollYbig::-webkit-scrollbar-corner {
  background: transparent !important;
}

.scrollYbig::-webkit-scrollbar {
  width: 12px !important;
}

.scrollXbig::-webkit-scrollbar-thumb,
.scrollYbig::-webkit-scrollbar-thumb {
  background-color: var(--divider3);
  border-radius: 100px;
}

.scrollXbigkotak::-webkit-scrollbar-thumb,
.scrollYbigkotak::-webkit-scrollbar-thumb {
  background-color: var(--divider2);
  border-radius: 0px !important;
}

.scrollXbig::-webkit-scrollbar-thumb:hover,
.scrollYbig::-webkit-scrollbar-thumb:hover {
  background-color: var(--divider3);
}

.scrollXbig::-webkit-scrollbar-track,
.scrollYbig::-webkit-scrollbar-track {
  background-color: var(--divider) !important;
  border-radius: 0 0 8px 8px;
}

.breadcrumb {
  width: fit-content;
  gap: 8px;
  background: var(--divider);
  border-radius: 8px;
  padding: 4px 16px;
  height: 30px;
}

.noofline1 {
  white-space: nowrap !important;
  overflow: hidden !important;
  text-overflow: ellipsis !important;
}

.breadcrumbItem,
.breadcrumbSeparator {
  font-size: 11px !important;
}

.breadcrumbCurrent {
  font-weight: 600;
  cursor: default;
}

.accordionItem {
  border-radius: 16px;
  border: 1px solid var(--divider);
  /* box-shadow: 1px 1px 2px 1px var(--divider); */
}

.chakra-form__helper-text {
  opacity: 0.4;
}

.accordionButton {
  padding: 24px;
  border-radius: 16px;
  justify-content: space-between;
  align-items: flex-start;
}

.ripple::after {
  content: "";
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 100px;
  height: 100px;
  background-color: rgba(255, 255, 255, 0.3);
  border-radius: 50%;
  animation: ripple 0.5s ease-out forwards;
  pointer-events: none; /* agar tidak mengganggu klik tombol */
}

.ripple-sm::after {
  content: "";
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 50px;
  height: 50px;
  background-color: var(--divider);
  border-radius: 50%;
  animation: ripple-sm 0.5s ease-out forwards;
  pointer-events: none; /* agar tidak mengganggu klik tombol */
}

/* Keyframes */
@keyframes ripple {
  to {
    width: 260px;
    height: 260px;
    opacity: 0;
  }
}

@keyframes ripple-sm {
  to {
    width: 120px;
    height: 120px;
    opacity: 0;
  }
}

@keyframes fade-in {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@keyframes fade-in-fade {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 0.4;
  }
}

@keyframes fade-out {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

@keyframes float-up {
  0% {
    transform: translateY(0);
  }
  100% {
    transform: translateY("5px");
  }
}

.skeleton {
  animation: flashing 1s ease-out infinite;
}

@keyframes flashing {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0.5;
  }
  100% {
    opacity: 1;
  }
}
/* Keyframes */

.chakra-popover__close-btn > svg {
  width: 10px !important;
}
.chakra-popover__body {
  padding-right: 36px !important;
}

.chakra-wrap__list {
  height: 100% !important;
}

.mirror-x {
  transform: scaleX(-1); /* Balikkan horizontal */
}

.chakra-popover__content:focus-visible {
  box-shadow: none !important;
}

.my-masonry-grid {
  display: flex;
  margin-left: -16px; /* gutter size offset */
  width: auto;
}
.my-masonry-grid_column {
  padding-left: 16px; /* gutter size */
  background-clip: padding-box;
}

/* Style your items */
.my-masonry-grid_column > div {
  margin-bottom: 16px;
}

/* Date Picker Start */
.rdp {
  margin: 0 !important;
}
.rdp-month {
  width: 100% !important;
}
.rdp-day_selected:focus-visible {
  outline: none !important;
}
.rdp-table {
  width: 100% !important;
  max-width: none !important;
}
.rdp-months {
  /* border: 1px solid var(--divider3) !important; */
  border-radius: 8px;
}
.rdp-caption_label {
  display: none;
  padding: 0 !important;
  margin: 0 !important;
  font-size: 18px;
  font-weight: 600;
}
.rdp-months {
  width: 100%;
  padding-top: 1px !important;
  align-items: stretch !important;
  justify-content: stretch !important;
}

.rdp-cell {
  height: 46px !important;
  text-align: center !important;
}

.rdp-head_cell {
  height: 40px !important;
}
.rdp-button {
  width: 36px !important;
  height: 36px !important;
  border-radius: 800px !important;
  transition: 200ms;
  /* margin-left: 5px !important; */
}
.rdp-button:focus-visible {
  background-color: transparent !important;
  border: none !important;
}

.rdp-day_today:hover {
  color: var(--p600);
}
.rdp-day_today {
  color: var(--p500);
  font-weight: 600;
}
.rdp-day_selected:not(.rdp-day_range_middle),
.rdp-day_range_start,
.rdp-day_range_end {
  background: var(--p500) !important;
  color: white !important;
  /* border: 2px solid var(--p500); */
  opacity: 1 !important;
}

.rdp-button:hover {
  /* background-color: var(--p500a2) !important; */
  border: 1px solid var(--p500) !important;
  opacity: 1 !important;
}

.rdp-day_selected:not(.rdp-day_range_middle):hover {
  background: var(--p500a2) !important;
}

.rdp-day_range_middle {
  background: var(--p500a4) !important;
  /* border: 1px solid var(--p500a1); */
}

.rdp-day_outside {
  opacity: 0.4;
}

.rdp-tfoot {
  display: none !important;
}
/* Date Picker End */

.doughnutChartContainer canvas {
  width: 220px !important;
  height: 220px !important;
  /* min-width: 200px !important;
  max-width: 250px !important;
  min-height: 200px !important;
  max-height: 250px !important; */
}

/* Custom Start*/

img[src^="/vectors/icons/hospital.svg"] {
  transform: scale(0.5) translateY(80px);
}

a.leaflet-attribution-flag {
  position: fixed !important;
  top: 4px !important;
  left: 4px !important;
}

img[alt="Google"] {
  position: fixed !important;
  top: 4px !important;
  left: 4px !important;
}

.gmnoprint,
.gm-style-cc {
  display: none;
}
/* Custom Start End*/

/* Media Querries */
@media screen and (min-width: 768px) {
  a {
    font-size: 16px !important;
  }
  p {
    font-size: 16px;
  }
  label {
    font-size: 16px !important;
  }
  td {
    font-size: 16px !important;
  }
  th {
    font-size: 14px !important;
  }
  .breadcrumbItem,
  .breadcrumbSeparator {
    font-size: 13px !important;
  }
  .chakra-alert__title {
    font-size: 16px;
  }
  .chakra-alert__desc {
    font-size: 16px;
  }
  .chakra-form__error-message {
    font-size: 14px !important;
  }
  .chakra-form__helper-text {
    font-size: 14px !important;
  }

  button {
    font-size: 16px !important;
  }
  input {
    font-size: 16px !important;
  }
  select {
    font-size: 16px !important;
  }
}
